@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Poppins:wght@400;500;600;700;800&family=Roboto+Slab:wght@200&display=swap');


@font-face {
	font-family: 'Roboto Slab', serif;
	font-style: normal;
	font-weight: 500;
	src: url('/src/fonts/RobotoSlap/RobotoSlab-VariableFont_wght.ttf'),
}

@font-face {
	font-family: 'Roboto Slab', serif;
	font-style: normal;
	font-weight: bold;
	src: url('/src/fonts/RobotoSlap/RobotoSlab-ExtraBold.ttf'),
}

@font-face {
	// font-family: 'Open Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 300;
	src: url('/src/fonts/Poppins/Poppins-Light.ttf'),
}

@font-face {
	// font-family: 'Open Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	src: url('/src/fonts/Poppins/Poppins-Regular.ttf'),
}

@font-face {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	src: url('/src/fonts/Poppins/Poppins-Medium.ttf'),
}

@font-face {
	// font-family: 'Open Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	src: url('/src/fonts/Poppins/Poppins-SemiBold.ttf'),
}

@font-face {
	// font-family: 'Open Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 800;
	src: url('/src/fonts/Poppins/Poppins-ExtraBold.ttf'),
}

@font-face {
	// font-family: 'Open Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 900;
	src: url('/src/fonts/Poppins/Poppins-Medium.ttf'),
}

@font-face {
	// font-family: 'Open Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: bold;
	src: url('/src/fonts/Poppins/Poppins-Black.ttf'),
}

@font-face {
	// font-family: 'Open Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	src: url('/src/fonts/Poppins/Poppins-Light.ttf'),
}